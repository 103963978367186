<template>
  <div>
    <v-layout wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#13736f"
        spinner="spinner"
      />
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <stats />
          </v-flex>
        </v-layout>
        <v-layout
          pt-3
          class="mainbg4"
          v-if="copylist.length > 0"
          justify-center
          wrap
        >
          <v-flex
            xs11
            align-self-center
            pb-6
            v-for="(item, i) in copylist"
            :key="i"
          >
            <v-card elevation="0" style="border-radius: 15px">
              <v-layout wrap fill-height justify-center>
                <v-flex
                  xs12
                  lg1
                  pl-6
                  align-self-center
                  style="cursor: pointer"
                  @click="$router.push('/DetailedPage?id=' + item._id)"
                >
                  <v-avatar size="90px">
                    <v-img :src="mediaURLnew + item.photos[0]"></v-img>
                  </v-avatar>
                </v-flex>

                <v-flex
                  style="cursor: pointer"
                  @click="$router.push('/DetailedPage?id=' + item._id)"
                  lg3
                  xs12
                  align-self-center
                >
                  <v-layout wrap justify-center>
                    <v-flex xs7 text-left pb-1>
                      <span
                        class="mainfont"
                        style="
                          color: #13736f;
                          font-size: 16px;
                          font-weight: bolder;
                        "
                        >Lot Number
                      </span>

                      <span
                        class="mainfont"
                        style="
                          color: #13736f;
                          font-size: 16px;
                          font-weight: bolder;
                        "
                      >
                        {{ item.lotNumber }}</span
                      >
                    </v-flex>

                    <v-flex xs7 text-left>
                      <span
                        class="mainfont"
                        style="
                          color: black;
                          font-size: 15px;
                          font-weight: lighter;
                        "
                        >{{ item.categoryId.name }}</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex
                  style="cursor: pointer"
                  @click="$router.push('/DetailedPage?id=' + item._id)"
                  lg2
                  xs12
                  align-self-center
                >
                  <v-layout wrap>
                    <v-flex xs8 text-left>
                      <span
                        class="mainfont"
                        style="
                          color: #13736f;
                          font-size: 16px;
                          font-weight: bolder;
                        "
                      >
                        Auction No {{ item.auctionId.auctionNumber }}
                      </span></v-flex
                    >
                    <v-flex xs8 text-left v-if="item.uid.traderId.name">
                      <v-icon color="black">mdi-account</v-icon>
                      <span
                        class="mainfont"
                        style="
                          color: black;
                          font-size: 15px;
                          font-weight: lighter;
                        "
                      >
                        {{ item.uid.traderId.name }}</span
                      >
                    </v-flex>
                    <v-flex xs12 pl-4 v-else>
                      <span
                        class="mainfont"
                        style="color: black; font-size: 16px"
                        >seller
                      </span>
                      <span
                        class="mainfont"
                        style="
                          color: black;
                          font-size: 15px;
                          font-weight: lighter;
                        "
                      >
                        -{{ item.uid.farmerId.name }}</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex
                  style="cursor: pointer"
                  @click="$router.push('/DetailedPage?id=' + item._id)"
                  lg2
                  xs12
                  pl-3
                  align-self-center
                >
                  <v-layout wrap>
                    <v-flex xs8 text-left lg12 pb-1>
                      <span
                        class="mainfont"
                        style="
                          color: #13736f;
                          font-size: 16px;
                          font-weight: bolder;
                        "
                      >
                        {{ formatDate(item.date) }}
                      </span></v-flex
                    >
                    <v-flex xs8 text-left>
                      <v-icon color="black">mdi-clock</v-icon>
                      <span
                        class="mainfont"
                        style="
                          color: black;
                          font-size: 15px;
                          font-weight: lighter;
                        "
                      >
                        {{ formatTime(item.endingTime) }}</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex lg1 xs12 align-self-center>
                  <v-layout wrap>
                    <v-flex xs8 text-left lg12 pb-1>
                      <v-btn
                        class="bg9"
                        width="100px"
                        @click="$router.push('/BidsPage?id=' + item._id)"
                      >
                        <span
                          class="mainfont"
                          style="color: white; font-size: 12px"
                        >
                          BIDS</span
                        >
                      </v-btn></v-flex
                    >

                    <v-flex xs8 text-left lg12 pr-3>
                      <v-btn
                        class="bg9"
                        v-if="
                          item.bidPrice > 0 &&
                          item.proceed < 20 &&
                          item.bidPrice < item.expectedPrice
                        "
                        color="#FCC201"
                        @click="handleButtonClick(item._id)"
                        width="100px"
                      >
                        <span
                          class="mainfont"
                          style="color: white; font-size: 12px"
                        >
                          PROCEED
                        </span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex text-left pt-4 pl-8 xs12 lg3 align-self-center>
                  <v-layout wrap justify-center>
                    <v-flex
                      v-if="item.bidPrice === 0"
                      pl-4
                      xs12
                      class="mainfont"
                      style="color: black; font-size: 14px"
                      >Current Price

                      <span
                        class="mainfont"
                        style="
                          color: #13736f;
                          font-size: 16px;
                          font-weight: bolder;
                        "
                      >
                        &nbsp; {{ item.startingPrice }}&nbsp;Rs
                      </span>
                    </v-flex>
                    <v-flex
                      v-else
                      pl-4
                      xs12
                      class="mainfont"
                      style="color: black; font-size: 14px"
                      >Current Price

                      <span
                        class="mainfont"
                        style="
                          color: #13736f;
                          font-size: 16px;
                          font-weight: bolder;
                        "
                      >
                        &nbsp; Rs {{ item.bidPrice }}
                      </span>
                    </v-flex>
                    <v-flex
                      pt-1
                      pl-4
                      xs12
                      class="mainfont"
                      style="color: black; font-size: 14px"
                      >Total Weight

                      <span
                        class="mainfont"
                        style="
                          color: #13736f;
                          font-size: 16px;
                          font-weight: bolder;
                        "
                      >
                        &nbsp; {{ item.netWeight }}&nbsp;kg
                      </span>
                    </v-flex>

                    <v-flex pt-1 xs12 pb-3 pl-2>
                      <v-layout wrap justify-center>
                        <v-flex xs11>
                          <v-card color="#13736f" elevation="0" class="pa-2">
                            <v-layout wrap>
                              <v-flex xs12 text-center>
                                <span
                                  class="mainfont"
                                  style="color: white; font-size: 14px"
                                >
                                  Ends in &nbsp;</span
                                >

                                <span
                                  class="mainfont"
                                  style="color: white; font-size: 16px"
                                  >{{ formatTimeDifference2(item.timer) }}</span
                                >
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <v-layout class="mainbg2" pt-4 pb-12 wrap justify-center>
              <v-flex align-self-center>
                <div>
                  <v-pagination
                    prev-icon="mdi-menu-left"
                    next-icon="mdi-menu-right"
                    :length="pages"
                    :total-visible="7"
                    v-model="currentPage"
                    color="#13736f"
                  ></v-pagination>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout class="mainbg4" v-else wrap justify-center>
          <v-flex xs12 text-center align-self-center>
            <span
              class="mainfont"
              style="color: black; font-size: 20px; font-weight: bold"
              >No live Auctions !</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-dialog
        :retain-focus="true"
        persistent
        v-model="dialog2"
        max-width="380px"
      >
        <v-card class="mainbg4 pa-2">
          <v-layout wrap justify-center>
            <v-flex
              ><v-layout wrap justify-center>
                <v-flex xs12 text-right>
                  <v-icon @click="dialog2 = false" color="red" size="150%" dark>
                    mdi-close
                  </v-icon>
                </v-flex>

                <v-flex xs12>
                  <v-card class="mainbg4" elevation="0">
                    <v-layout justify-center wrap width="100px">
                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          <v-flex
                            xs8
                            text-center
                            class="mainfont"
                            style="
                              color: black;
                              font-size: 16px;
                              font-weight: bold;
                            "
                            >Choose Action</v-flex
                          >
                          <v-flex xs8 pr-6 class="mainfont">
                            <v-radio-group v-model="won">
                              <v-radio
                                color="#13736f"
                                label="Won"
                                :disabled="withdraw"
                                value="Won"
                              ></v-radio>
                              <v-radio
                                color="#13736f"
                                label="Withdraw"
                                ref=""
                                value="Withdraw"
                                :disabled="Won"
                              ></v-radio>
                            </v-radio-group>
                          </v-flex>
                          <v-flex
                            text-center
                            class="mainfont"
                            style="
                              color: black;
                              font-size: 16px;
                              font-weight: bold;
                            "
                            xs14
                            v-if="won === 'Won'"
                          >
                            <span
                              v-if="
                                getbids.uid.traderId &&
                                getbids.uid.traderId.name
                              "
                            >
                              {{ getbids.uid.traderId.name }}
                            </span>
                            made the latest bid of Rs
                            <span class="mainfont">{{
                              getbids.bidAmount
                            }}</span>
                          </v-flex>

                          <v-flex xs6 pl-10 pt-4>
                            <v-btn
                              color="#13736f"
                              width="100px"
                              class="no-uppercase"
                              @click="submitAction(itemId)"
                            >
                              <span
                                class="mainfont"
                                style="color: white; font-size: 13px"
                              >
                                Submit
                              </span>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>
    <script>
import moment from "moment";
import axios from "axios";
import stats from "./Statistics.vue";

export default {
  components: {
    stats,
  },

  data() {
    return {
      showSnackBar: false,
      customHeight: 550,

      ServerError: false,
      timeout: 5000,
      itemArray: ["Pending", "Rejected", "Inactive", "Active"],
      msg: null,
      pages: 0,
      dialog2: false,
      dialog: false,
      editdialog: false,
      editingitem: [],
      won: null,
      name: "",
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      perPage: 4,
      totalData: 0,
      status: null,
      statistics: [],
      totalRows: 0,
      dialogDelete: false,
      withdraw: null,
      search: "",
      itemname: "",
      list: [],
      data: [],
      coverImageFile: null,
      Won: null,
      selectedFiles: [],
      image: null,
      role: null,
      itemId: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      flagg: true,
      menu: false,
      menu1: false,
      menu2: false,
      menu22: false,
      departmentList: [],
      projectList: null,
      positionList: null,
      project: null,
      department: null,
      empList1: null,
      empList2: null,
      toDate: null,
      fromDate: null,
      search1: null,
      search2: null,
      copylist: [],
      search3: null,
      search11: null,
      search22: null,
      searchInput: null,
      showButton: true,
      itemsToShow: 3,
      search33: null,
      currentTime: null,
      keysearch: null,
      ffprojectList: null,
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      ffposition: null,
      getbids: {},
      moreFilter: false,
    };
  },
  computed: {
    displayedItems() {
      return this.myArray.slice(0, this.itemsToShow);
    },
  },
  created() {
    this.updateProceedRealTime();
    this.currentTime = new Date();
    this.getData();
  },

  watch: {
    status() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
    currentPage() {
      this.getData();
    },
    fromDate() {
      this.currentPage = 1;
      this.getData();
    },
    toDate() {
      this.currentPage = 1;
      this.getData();
    },
  },
  mounted() {
    this.getData();
    this.startTimers();
  },

  methods: {
    submitAction(itemId) {
      if (this.won === "Won") {
        this.handleWon(itemId);
      } else if (this.won === "Withdraw") {
        this.handleWithdrawn(itemId);
      }
    },
    handleWon(itemId) {
      axios({
        method: "POST",
        url: "/live/lot/proceed",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          uid: this.getbids.uid._id,
          status: "Won",
          id: itemId,
          bidAmount: this.getbids.bidAmount,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.dialog2 = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    handleWithdrawn(itemId) {
      axios({
        method: "POST",
        url: "/live/lot/proceed",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          status: "Withdrawn",
          id: itemId,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.dialog2 = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    handleButtonClick(itemId) {
      this.getBids(itemId);
      this.dialog2 = true;
      this.itemId = itemId;
    },
    getBids(itemId) {
      axios({
        method: "GET",
        url: "/live/lot/latest/bid",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: itemId,
        },
      })
        .then((response) => {
          this.getbids = response.data.data;
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    updateProceedRealTime() {
      setInterval(() => {
        this.copylist.forEach((item) => {
          item.balanceDuration = this.calculateBalanceDuration(item.endingTime);
          item.proceed =
            (this.parseDurationToSeconds(item.balanceDuration) /
              this.parseDurationToSeconds(item.totalDuration)) *
            100;
          item.proceed = Math.floor(item.proceed);
        });
      }, 1000);
    },

    formatTimeDifference2(seconds) {
      if (typeof seconds === "number" && seconds >= 0) {
        const hours = Math.floor(seconds / 3600);
        const remainingSeconds = seconds % 3600;
        const minutes = Math.floor(remainingSeconds / 60);
        const remainingSecondsFinal = remainingSeconds % 60;
        return `${this.formatTwoDigits(hours)}:${this.formatTwoDigits(
          minutes
        )}:${this.formatTwoDigits(remainingSecondsFinal)}`;
      } else {
        return "Invalid Time Difference";
      }
    },
    formatTwoDigits(number) {
      return String(number).padStart(2, "0");
    },
    formatTimeDifference(seconds) {
      if (typeof seconds === "number" && seconds >= 0) {
        const hours = Math.floor(seconds / 3600);
        const remainingSeconds = seconds % 3600;
        const minutes = Math.floor(remainingSeconds / 60);
        const remainingSecondsFinal = remainingSeconds % 60;
        return `${this.formatTwoDigits(hours)}:${this.formatTwoDigits(
          minutes
        )}:${this.formatTwoDigits(remainingSecondsFinal)}`;
      } else {
        return "Invalid Time Difference";
      }
    },
    startTimer(item) {
      item.timer = setInterval(() => {
        if (item.timer > 0) {
          item.timer--;
        } else {
          clearInterval(item.timer);
        }
      }, 1000);
    },
    startTimers() {
      for (const item of this.copylist) {
        this.startTimer(item);
      }
    },

    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(time) {
      return moment(time).format("HH:mm");
    },
    getData() {
      this.appLoading = true;
      axios
        .get("/live/lot/list", {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            status: this.status,
            keyword: this.keysearch,
            page: this.currentPage,
            fromDate: this.fromDate,
            toDate: this.toDate,
            limit: 10,
          },
        })
        .then((response) => {
          this.appLoading = false;
          this.list = response.data.data;
          this.copylist = this.list;
          const timeProperty = "time";
          this.startTimers();
          this.endingTime = this.copylist.map(
            (item) => new Date(item.endingTime)
          );
          this.calculateTimeDifference();
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.copylist.forEach((item) => {
            item.timer = item[timeProperty];
            delete item[timeProperty];
            item.totalDuration = this.calculateDuration(
              item.startingTime,
              item.endingTime
            );
            item.balanceDuration = this.calculateBalanceDuration(
              item.endingTime
            );
            item.proceed =
              (this.parseDurationToSeconds(item.balanceDuration) /
                this.parseDurationToSeconds(item.totalDuration)) *
              100;
          });
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    parseDurationToSeconds(duration) {
      const timeComponents = duration.split(" ");
      let seconds = 0;
      timeComponents.forEach((component) => {
        if (component.includes("h")) {
          seconds += parseInt(component, 10) * 3600;
        } else if (component.includes("m")) {
          seconds += parseInt(component, 10) * 60;
        } else if (component.includes("s")) {
          seconds += parseInt(component, 10);
        }
      });
      return seconds;
    },

    calculateBalanceDuration(endingTime) {
      const end = new Date(endingTime);
      const current = new Date();
      const diffMilliseconds = end.getTime() - current.getTime();
      const diffSeconds = Math.floor(diffMilliseconds / 1000);
      const hours = Math.floor(diffSeconds / 3600);
      const minutes = Math.floor((diffSeconds % 3600) / 60);
      const seconds = diffSeconds % 60;
      return `${hours}h ${minutes}m ${seconds}s`;
    },
    calculateDuration(startTime, endTime) {
      const start = new Date(startTime);
      const end = new Date(endTime);
      const diffMilliseconds = end.getTime() - start.getTime();
      const diffSeconds = Math.floor(diffMilliseconds / 1000);
      const hours = Math.floor(diffSeconds / 3600);
      const minutes = Math.floor((diffSeconds % 3600) / 60);
      const seconds = diffSeconds % 60;
      return `${hours}h ${minutes}m ${seconds}s`;
    },

    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    calculateTimeDifference() {
      if (this.currentTime instanceof Date && Array.isArray(this.endingTime)) {
        const timeDifferences = this.endingTime.map((endingTime) => {
          const timeDifference =
            endingTime.getTime() - this.currentTime.getTime();
          return Math.floor(timeDifference / 1000);
        });
        this.copylist.forEach((item, index) => {
          item.time = timeDifferences[index];
        });
      }
    },
  },
};
</script>
<style >
.bg8 {
  border-radius: 10px;
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}

.bg9 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.carousel-container {
  max-width: 800px;
  margin: 0 auto;
}

.card {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  margin: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.round-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%; /* Make it round */
  border: 2px solid #f00; /* Set the border color */
  background-color: transparent; /* Transparent background */
  outline: none;
  cursor: pointer;
}

.round-checkbox input[type="checkbox"]:checked {
  background-color: #f00; /* Change background color when checked */
}
</style>
    