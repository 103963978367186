<template>
  <div>
    <v-layout v-if="statistics.length == 1" wrap justify-center>
      <v-flex xs5 v-for="(item, index) in statistics" :key="index">
        <v-layout wrap class="card bg8">
          <v-flex>
            <v-layout wrap justify-center>
              <v-flex xs4 pb-3 align-self-center>
                <v-avatar size="90px">
                  <v-img :src="mediaURLnew + item.categoryIcon"></v-img>
                </v-avatar>
              </v-flex>
              <v-flex xs12 align-self-center>
                <v-layout wrap justify-center>
                  <v-flex align-self-center>
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-center align-self-center>
                        <span
                          class="mainfont"
                          style="font-size: 18px; color: white"
                        >
                          Average
                        </span>
                        <span
                          v-if="
                            item &&
                            (item.average !== undefined || item.average === 0)
                          "
                          class="mainfont"
                          style="font-size: 18px; color: white"
                        >
                          &nbsp; Rs {{ item.average }}
                        </span>
                      </v-flex>
                      <v-flex xs12 text-center align-self-center>
                        <span
                          class="mainfont"
                          style="font-size: 18px; color: white"
                        >
                          {{ item.categoryName }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 align-self-center>
                <v-layout wrap justify-center>
                  <v-flex xs6 pt-2 align-self-center>
                    <v-layout wrap justify-center>
                      <v-flex xs12 pr-2 text-left>
                        <span
                          class="mainfont"
                          style="font-size: 14px; color: white"
                        >
                          Total Lots &nbsp;{{ item.totalLots }}
                        </span>
                      </v-flex>
                      <v-flex xs12 pr-2 text-left>
                        <span
                          class="mainfont"
                          style="font-size: 14px; color: white"
                        >
                          Withdraw Lots&nbsp;{{ item.withdrawnLots }}
                        </span>
                      </v-flex>
                      <v-flex xs12 pr-2 text-left>
                        <span
                          class="mainfont"
                          style="font-size: 14px; color: white"
                        >
                          Max Price &nbsp; Rs&nbsp;{{ item.maximum }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6 pt-2 text-right align-self-center>
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-right>
                        <span
                          class="mainfont"
                          style="font-size: 14px; color: white"
                        >
                          Won &nbsp;{{ item.soldLots }}
                        </span>
                      </v-flex>
                      <v-flex xs12 text-right>
                        <span
                          class="mainfont"
                          style="font-size: 14px; color: white"
                        >
                          Remaining Lots&nbsp;
                          {{ item.remainingLots }}
                        </span>
                      </v-flex>
                      <v-flex xs12 text-right>
                        <span
                          class="mainfont"
                          style="font-size: 14px; color: white"
                        >
                          Min Price&nbsp; Rs&nbsp;{{ item.minimum }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="statistics.length === 2">
      <v-flex xs5 v-for="(item, index) in statistics" :key="index">
        <v-layout wrap class="card bg8">
          <v-flex>
            <v-layout wrap justify-center>
              <v-flex xs4 pb-3 align-self-center>
                <v-avatar size="90px">
                  <v-img :src="mediaURLnew + item.categoryIcon"></v-img>
                </v-avatar>
              </v-flex>
              <v-flex xs12 align-self-center>
                <v-layout wrap justify-center>
                  <v-flex align-self-center>
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-center align-self-center>
                        <span
                          class="mainfont"
                          style="font-size: 18px; color: white"
                        >
                          Average
                        </span>
                        <span
                          v-if="
                            item &&
                            (item.average !== undefined || item.average === 0)
                          "
                          class="mainfont"
                          style="font-size: 18px; color: white"
                        >
                          &nbsp; Rs {{ item.average }}
                        </span>
                      </v-flex>
                      <v-flex xs12 text-center align-self-center>
                        <span
                          class="mainfont"
                          style="font-size: 18px; color: white"
                        >
                          {{ item.categoryName }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 align-self-center>
                <v-layout wrap justify-center>
                  <v-flex xs6 pt-2 align-self-center>
                    <v-layout wrap justify-center>
                      <v-flex xs12 pr-2 text-left>
                        <span
                          class="mainfont"
                          style="font-size: 14px; color: white"
                        >
                          Total Lots &nbsp;{{ item.totalLots }}
                        </span>
                      </v-flex>
                      <v-flex xs12 pr-2 text-left>
                        <span
                          class="mainfont"
                          style="font-size: 14px; color: white"
                        >
                          Withdraw Lots&nbsp;{{ item.withdrawnLots }}
                        </span>
                      </v-flex>
                      <v-flex xs12 pr-2 text-left>
                        <span
                          class="mainfont"
                          style="font-size: 14px; color: white"
                        >
                          Max Price &nbsp; Rs&nbsp;{{ item.maximum }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6 pt-2 text-right align-self-center>
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-right>
                        <span
                          class="mainfont"
                          style="font-size: 14px; color: white"
                        >
                          Won &nbsp;{{ item.soldLots }}
                        </span>
                      </v-flex>
                      <v-flex xs12 text-right>
                        <span
                          class="mainfont"
                          style="font-size: 14px; color: white"
                        >
                          Remaining Lots&nbsp;
                          {{ item.remainingLots }}
                        </span>
                      </v-flex>
                      <v-flex xs12 text-right>
                        <span
                          class="mainfont"
                          style="font-size: 14px; color: white"
                        >
                          Min Price&nbsp; Rs&nbsp;{{ item.minimum }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="statistics && statistics.length > 2">
      <v-flex xs12 pt-5>
        <div class="autoplay-card">
          <transition-group
            name="carousel-slide"
            tag="div"
            class="card-carousel"
          >
            <div
              v-for="(item, index) in visibleItems"
              :key="index"
              class="card bg8"
            >
              <v-layout wrap justify-center>
                <v-flex xs4 pb-3 align-self-center>
                  <v-avatar size="90px">
                    <v-img :src="mediaURLnew + item.categoryIcon"></v-img>
                  </v-avatar>
                </v-flex>
                <v-flex xs12 align-self-center>
                  <v-layout wrap justify-center>
                    <v-flex align-self-center>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-center align-self-center>
                          <span
                            class="mainfont"
                            style="font-size: 18px; color: white"
                          >
                            Average
                          </span>
                          <span
                            v-if="
                              item &&
                              (item.average !== undefined || item.average === 0)
                            "
                            class="mainfont"
                            style="font-size: 18px; color: white"
                          >
                            &nbsp; Rs {{ item.average }}
                          </span>
                        </v-flex>
                        <v-flex xs12 text-center align-self-center>
                          <span
                            class="mainfont"
                            style="font-size: 18px; color: white"
                          >
                            {{ item.categoryName }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 align-self-center>
                  <v-layout wrap justify-center>
                    <v-flex xs6 pt-2 align-self-center>
                      <v-layout wrap justify-center>
                        <v-flex xs12 pr-2 text-left>
                          <span
                            class="mainfont"
                            style="font-size: 14px; color: white"
                          >
                            Total Lots &nbsp;{{ item.totalLots }}
                          </span>
                        </v-flex>
                        <v-flex xs12 pr-2 text-left>
                          <span
                            class="mainfont"
                            style="font-size: 14px; color: white"
                          >
                            Withdraw Lots&nbsp;{{ item.withdrawnLots }}
                          </span>
                        </v-flex>
                        <v-flex xs12 pr-2 text-left>
                          <span
                            class="mainfont"
                            style="font-size: 14px; color: white"
                          >
                            Max Price &nbsp; Rs&nbsp;{{ item.maximum }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 pt-2 text-right align-self-center>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-right>
                          <span
                            class="mainfont"
                            style="font-size: 14px; color: white"
                          >
                            Won &nbsp;{{ item.soldLots }}
                          </span>
                        </v-flex>
                        <v-flex xs12 text-right>
                          <span
                            class="mainfont"
                            style="font-size: 14px; color: white"
                          >
                            Remaining Lots&nbsp;
                            {{ item.remainingLots }}
                          </span>
                        </v-flex>
                        <v-flex xs12 text-right>
                          <span
                            class="mainfont"
                            style="font-size: 14px; color: white"
                          >
                            Min Price&nbsp; Rs&nbsp;{{ item.minimum }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </div>
          </transition-group>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import axios from "axios";

export default {
  data() {
    return {
      statistics: [],
      currentIndex: 0,
      intervalId: null,
    };
  },
  computed: {
    visibleItems() {
      const middleIndex = (this.currentIndex + 1) % this.statistics.length;
      const endIndex = (this.currentIndex + 2) % this.statistics.length;
      return [
        this.statistics[this.currentIndex],
        this.statistics[middleIndex],
        this.statistics[endIndex],
      ];
    },
  },

  mounted() {
    this.getStatistics();
  },
  created() {
    this.startAutoPlay();
  },
  beforeDestroy() {
    this.stopAutoPlay();
  },
  methods: {
    getStatistics() {
      this.appLoading = true;
      axios
        .get("/live/lot/statistics", {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.appLoading = false;
          this.statistics = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    startAutoPlay() {
      this.intervalId = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.statistics.length;
      }, 3000);
    },
    stopAutoPlay() {
      clearInterval(this.intervalId);
      this.intervalId = null;
    },
  },
};
</script>
  
  <style scoped>
.autoplay-card {
  width: 100%;
  overflow: hidden;
}

.card {
  flex: 0 0 31.46%;
  padding: 20px;
  border: 1px solid #e7f1f0;
  box-sizing: border-box;
  white-space: normal;
}

.card-carousel {
  display: flex;
}
</style>
  